
import { createSlice } from '@reduxjs/toolkit';

const extRefSlice = createSlice({
  name: 'extRef',
  initialState: null,
  reducers: {
    setExtRef: (state, action) => action.payload,
    clearExtRef: () => null,
  },
});

export const { setExtRef, clearExtRef } = extRefSlice.actions;

export default extRefSlice.reducer;
