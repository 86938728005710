const baseUrl=`https://oor.toon-flix.com`;

const requestPin=`${baseUrl}/api/v1/generatePin`;
export {requestPin}

const validatePin=`${baseUrl}/api/v1/subscribe`;
export {validatePin}

const unsubscribe=`${baseUrl}/api/v1/UnSubscribe`;
export {unsubscribe}
