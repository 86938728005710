
import { configureStore } from '@reduxjs/toolkit';
import videoReducer from './Slice'
import languageReducer from './languageSlice'
import extRefReducer from './extRefSlice'
import msisdnReducer from './msisdnSlice'

const store = configureStore({
  reducer: {
    video: videoReducer,
    language:languageReducer,
    extRef:extRefReducer,
    msisdn:msisdnReducer,

  },
});

export default store;