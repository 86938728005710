
import { createSlice } from '@reduxjs/toolkit';

const msisdnSlice = createSlice({
  name: 'msisdn',
  initialState: null,
  reducers: {
    setMsisdn: (state, action) => action.payload,
    clearMsisdn: () => null,
  },
});

export const { setMsisdn, clearMsisdn } = msisdnSlice.actions;
export default msisdnSlice.reducer;
